import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["filterSection", "form", "searchInput", "sortBy"];

  connect() {
    this.tomSelectInstances = {};
    this.initializeTomSelect();
    this.formTarget.addEventListener('change', this.submitForm.bind(this));
    this.searchInputTarget.addEventListener('input', this.resetFilters.bind(this));
    
    // Show the filter section if there are existing params
    this.checkInitialParams();
  }

  initializeTomSelect() {
    const tomSelectOptions = {
      create: false,
      maxOptions: 10,
      load: (query, callback) => {
        if (!query.length) return callback();
        fetch(`/search?query=${query}`)
          .then(response => response.json())
          .then(data => callback(data.items))
          .catch(() => callback());
      },
    };

    this.tomSelectInstances.company = this.initializeSingleTomSelect("company", tomSelectOptions);
    this.tomSelectInstances.job_role = this.initializeSingleTomSelect("job_role", tomSelectOptions);
    this.tomSelectInstances.hire_type = this.initializeSingleTomSelect("hire_type", tomSelectOptions);
  }

  initializeSingleTomSelect(targetName, options) {
    const element = this.element.querySelector(`select[name="${targetName}"]`);
    if (element) {
      return new TomSelect(element, options);
    }
    return null;
  }

  toggle() {
    this.filterSectionTarget.classList.toggle("hidden");
  }

  submitForm() {
    this.formTarget.requestSubmit();
    // No need to hide the filter section here
  }

  resetFilters() {
    // Clear the filter fields
    this.clearTomSelect("company");
    this.clearTomSelect("job_role");
    this.clearTomSelect("hire_type");

    // Reset the sort by dropdown
    const sortBySelect = this.sortByTarget;
    if (sortBySelect) {
      sortBySelect.selectedIndex = 0; // Reset to the first option
    }
  }

  clearTomSelect(targetName) {
    const tomSelectInstance = this.tomSelectInstances[targetName];
    if (tomSelectInstance) {
      tomSelectInstance.clear();
    }
  }

  checkInitialParams() {
    const hasParams = Object.values(this.tomSelectInstances).some(instance => {
      return instance.getValue() !== ''; // Check if any of the dropdowns have a selected value
    });
    
    if (hasParams) {
      this.filterSectionTarget.classList.remove("hidden");
    }
  }
}
