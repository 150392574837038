import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  export(event) {
    // Prevent the default link behavior
    event.preventDefault();

    // Get the export URL from the data attribute
    const url = this.data.get("url");
    
    // Create a temporary link to download the CSV
    if (url) {
      const downloadUrl = `${url}.csv`;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'export.csv'; // Name the file to download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    }
  }
}
